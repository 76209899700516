import React, {useState }from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus'
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
//import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import  useToken from './useToken';

const focusOnError = createDecorator()

export const FundRequest = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { from,request_type } = location.state;
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const { userInfo, getToken } = useToken();
    var amount_desc="";

    if (request_type === "INVEST") {
        amount_desc="Amount to be Invested";
    }
    else if (request_type === "REDEEM") {
        amount_desc="Amount to be Redeemed";
    }

    const validate = (data) => {
        let errors = {};

        if (!data.amount) {
            errors.pincode = 'Amount is required.';
        }                
        else if (!/^[0-9]+$/i.test(data.amount)) {
            errors.pincode = 'Invalid Amount in Pincode';
        }

        return errors;
    };

    const onSubmit = async(data, form) => {
        data.client_id=userInfo.clientid;
         const response = await fetch('/raffles/fundRequest.php', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            }
            }
        );

        var fundResponse = await response.json();
        //console.log(userResponse.userid);
        setFormData(fundResponse);
        setShowMessage(true);
    };

    function FundResponse() {   
        if (formData.message === "Success") {
            return  (
                <>
                <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                <h5>Sub Fund Creation Successful!</h5>                    
                </>
            );  
        }
        else {
            return  (
                <>
                <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                <h5>Sub Fund Creation Failed!</h5>
                <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                    <b>{formData.message}</b>
                </p>
                </>
            );
        }        
    }

    function onShowMessageClick() {
        setShowMessage(false);
        if (formData.message === "Success") {         
            navigate('/UserDashboard')
        }
    }

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={onShowMessageClick } /></div>;
    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };

    return (       
        <div className="register-form">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">                    
                    <FundResponse  />
                </div>
            </Dialog>
            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h5 className="p-text-center">Fund Request</h5>
                    <Divider type="solid" />
                    <Form onSubmit={onSubmit} 
                        decorators={[focusOnError]}
                        initialValues={{ sub_fund_id: from.sub_fund_id,sub_fund_name: from.sub_fund_name, request_type: request_type, amount:0}} 
                        validate={validate} 
                        render={({ handleSubmit, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit} className="p-fluid">
                                <Field name="sub_fund_id" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="sub_fund_id" {...input} readOnly className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <label htmlFor="sub_fund_id" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Sub Fund ID*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="sub_fund_name" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="sub_fund_name" {...input} readOnly className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <label htmlFor="sub_fund_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Sub Fund Name*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="request_type" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="request_type" {...input} readOnly className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <label htmlFor="request_type" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Sub Fund Type*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="amount" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="amount" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <label htmlFor="amount" className={classNames({ 'p-error': isFormFieldValid(meta) })}>{amount_desc}*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Button type="submit" label="Submit" className="p-mt-2" disabled={submitting || pristine}/>
                            </form>
                        )} />
                    </div>
                </div>
        </div>
    );
}