import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom'
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Steps } from 'primereact/steps';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import  useToken from './useToken';
import './LoginForm.css';

const focusOnError = createDecorator()

export const RegisterKYC = () => {
    const [countries, setCountries] = useState([]);    
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(2);
    const [validationType, setValidationType] = useState(0);
    const { userInfo, getToken } = useToken();
    
    const items = [        
        {
            label: 'UserId creation'
            
        },
        {
            label: 'Personal'
            
        },
        {
            label: 'KYC details'
        },
        {
            label: 'Upload Documents'
        }
    ];
    
    const identitySource = ['Identity Card', 'Passport Details'];

    if (!userInfo) {
        navigate('/Login')
    }

    useEffect(() => {
        fetch('/raffles/get_countries_array.php')
        .then(result => result.json())
        .then(countryData => setCountries(countryData))
        }, []);

    const validate = (data) => {
        let errors = {};       

        if (!data.bank_name) {
            errors.bank_name = 'Bank Name is required.';
        }

        if (!data.bank_account_name) {
            errors.bank_account_name = 'Bank Account Name is required.';
        }

        if (!data.bank_account_number) {
            errors.bank_account_number = 'Bank Account Number is required.';
        }

        if (!data.bank_swift_code) {
            errors.bank_swift_code = 'Bank Swift code is required.';
        }

        if (!data.bank_address) {
            errors.bank_address = 'Bank Address is required.';
        }

        if (!data.bank_city) {
            errors.bank_city = 'Bank City is required.';
        }

        if (!data.bank_country) {
            errors.bank_country = 'Bank Country is required.';
        }

        return errors;
    };

    const onSubmit = async(data, form) => {
        var validationOk=false;
        
        setValidationType(0);
        
        if (data.nationalityInfo) {
            if (data.nationalityInfo.length > 0) {
                validationOk=true;
            }            
        }       

        if (!validationOk) {
            setValidationType(1);
            setShowMessage(true);
            return;
        }

        validationOk=false;
        
        if (data.taxation) {
            if (data.taxation.length > 0) {
                validationOk=true;
            }                
        }

        if (!validationOk) {
            setValidationType(2);
            setShowMessage(true);
            return;
        }
        
        if (validationOk) {
            data.userid=userInfo.userid;
            //console.log(JSON.stringify(data));
            for (let i in data.nationalityInfo) {                
                if (data.nationalityInfo[i].identitySource === 'Passport Details') {
                    data.nationalityInfo[i].passport_issue = new Date(Date.UTC(data.nationalityInfo[i].passport_issue.getFullYear(), 
                        data.nationalityInfo[i].passport_issue.getMonth(), data.nationalityInfo[i].passport_issue.getDate()))
                    data.nationalityInfo[i].passport_expiry = new Date(Date.UTC(data.nationalityInfo[i].passport_expiry.getFullYear(), 
                        data.nationalityInfo[i].passport_expiry.getMonth(), data.nationalityInfo[i].passport_expiry.getDate()))                    
                }                
            }
            const response = await fetch('/raffles/registerkyc.php', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                "Content-type": "application/json; charset=UTF-8"
                }
                }
            );

            var userResponse = await response.json();
            
            setFormData(userResponse);
            setShowMessage(true); 
        }
    };       
   
    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };
   

    function RegisterResponse() {   
        if (validationType === 0) {     
            if (formData.message === "Success") {
                return  (
                    <>
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Registration Successful!</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        Your account is registered under name <b>{userInfo.username}</b>. Please check <b>{formData.email}</b> for activation instructions.
                    </p>
                    </>
                );  
            }
            else {
                return  (
                    <>
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Registration Failed!</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        <b>{formData.message}</b>
                    </p>
                    </>
                );
            }
        }
        else if (validationType === 1) {
            return  (
                <div><h4>Please click on Add Nationality</h4></div>
            );
        }
        else if (validationType === 2) {
            return  (
                <div><h4>Please click on Add Taxation</h4></div>
            );
        }
    }

    function onShowMessageClick() {
        setShowMessage(false);
        if (formData.message === "Success") {         
            navigate('/RegisterUpload')
        }
    }

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    const Condition = ({ when, is, children }) => (
        <Field name={when} subscription={{ value: true }}>
          {({ input: { value } }) => (value === is ? children : null)}
        </Field>        
    )

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={onShowMessageClick } /></div>;
    const required = (value) => (value ? undefined : "Required");
    const composeValidators = (...validators) => (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

    return (
        <div className="register-form">
            
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">                    
                    <RegisterResponse  />
                </div>
            </Dialog>

            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h5 className="p-text-center">Registration</h5>
                    {/* <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} /> */}
                    <Steps model={items} activeIndex={activeIndex} readOnly={true} />

                    <Form 
                        onSubmit={onSubmit} 
                        decorators={[focusOnError]}
                        mutators={{
                            ...arrayMutators
                          }}
                        initialValues={{ userid: '',  
                            bank_name:'', bank_account_name:'', bank_account_number:'', bank_swift_code:'', bank_address:'', bank_city:'', bank_country:null,
                            cb_bank_name:'',  cb_bank_swift_code:'', cb_clearing_code:'', cb_nostro_number:''}} 
                        validate={validate} 
                        render={({ handleSubmit, submitting, pristine,
                            form: {
                                mutators: { push, pop }
                            }, // injected from final-form-arrays above
                            values }) => (
                        <form onSubmit={handleSubmit} className="p-fluid">                            
                            <div style={{marginBottom: '2em'}}>
                                <Divider align="center">
                                    <span className="p-tag">Bank Details</span>
                                </Divider>
                            </div>
                            <div className="p-formgroup-inline">
                                <Field name="bank_name" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <InputText id="bank_name" {...input} autoFocus className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="bank_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Name of Bank</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="bank_account_name" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <InputText id="bank_account_name" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="bank_account_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Account Name</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="bank_account_number" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="bank_account_number" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="bank_account_number" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Account Number</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="bank_swift_code" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <InputText id="bank_swift_code" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="bank_swift_code" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Swift Code</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                            </div>
                            <div className="p-formgroup-inline">                                
                                <Field name="bank_address" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '32em'}}>
                                            <InputText id="bank_address" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="bank_address" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Bank Address</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="bank_city" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <InputText id="bank_city" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="bank_city" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Bank City</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="bank_country" render={({ input,meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <Dropdown id="bank_country" {...input} options={countries} className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/>
                                            <label htmlFor="bank_country" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Bank Country</label>
                                        </span>
                                    </div>
                                )} />
                            </div>
                            <div style={{marginBottom: '2em'}}>
                                <Divider align="center">
                                    <span className="p-tag">Correspondent Bank Details (CB)</span>
                                </Divider>
                            </div>
                            <div className="p-formgroup-inline">
                                <Field name="cb_bank_name" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <InputText id="cb_bank_name" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="cb_bank_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>CB Bank Name</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="cb_bank_swift_code" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <InputText id="cb_bank_swift_code" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="cb_bank_swift_code" className={classNames({ 'p-error': isFormFieldValid(meta) })}>CB Bank Swift Code</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="cb_clearing_code" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <InputText id="cb_clearing_code" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="cb_clearing_code" className={classNames({ 'p-error': isFormFieldValid(meta) })}>CB Clearing Code</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="cb_nostro_number" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <InputText id="cb_nostro_number" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="cb_nostro_number" className={classNames({ 'p-error': isFormFieldValid(meta) })}>CB Nostro Account Number</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                            </div>
                            <div>
                                <Divider align="center">
                                    <span className="p-tag">Nationality</span>
                                </Divider>
                            </div>                            
                            <div className="p-field" style={{marginBottom: '2em'}}>
                                <Button label="Add Nationality" style={{width: '15em'}} onClick={() => push('nationalityInfo', undefined) } />
                            </div>
                            <FieldArray name="nationalityInfo"> 
                                {({ fields }) => fields.map((name, index) => (
                                    <>
                                    <div className="p-formgroup-inline">
                                        <Field name={`${name}.nationality`} validate={composeValidators(required)} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label" style={{width: '16em'}}>
                                                    <Dropdown id={`${name}.nationality`} {...input} options={countries}                                                      
                                                        className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                                    <label htmlFor={`${name}.nationality`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Nationality*</label>
                                                </span>
                                            </div>
                                        )} />
                                        <Field name={`${name}.identitySource`} validate={composeValidators(required)} render={({ input,meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label" style={{width: '16em'}}>
                                                    <Dropdown id={`${name}.identitySource`} {...input} options={identitySource} 
                                                        className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/> 
                                                    <label htmlFor={`${name}.identitySource`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Identity Source</label>
                                                </span>
                                            </div>
                                        )} />
                                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => fields.remove(index)}/>
                                    </div>                                    
                                    <Condition when={`${name}.identitySource`} is='Identity Card'>
                                        <div className="p-formgroup-inline" style={{marginTop: '0.5em'}} key={name} >                                            
                                            <Field name={`${name}.identity_card`} validate={composeValidators(required)} render={({ input, meta }) => (
                                                <div className="p-field">
                                                    <span className="p-float-label">
                                                        <InputText id={`${name}.identity_card`} {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                                        <label htmlFor={`${name}.identity_card`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Identity Card (If Applicable)</label>   
                                                    </span>
                                                    {getFormErrorMessage(meta)}
                                                </div>
                                            )} />                                            
                                        </div>
                                    </Condition>
                                    <Condition when={`${name}.identitySource`} is='Passport Details'>
                                        <div className="p-formgroup-inline" style={{marginTop: '0.5em'}} key={name}>                                            
                                            <Field name={`${name}.passport_no`} validate={composeValidators(required)} render={({ input, meta }) => (
                                                <div className="p-field">
                                                    <span className="p-float-label">
                                                        <InputText id={`${name}.passport_no`} {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                                        <label htmlFor={`${name}.passport_no`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Passport Number*</label>   
                                                    </span>
                                                    {getFormErrorMessage(meta)}
                                                </div>
                                            )} />
                                            <Field name={`${name}.passport_issue`} validate={composeValidators(required)} render={({ input,meta }) => (
                                                <div className="p-field">
                                                    <span className="p-float-label" >
                                                        <Calendar id={`${name}.passport_issue`} {...input} dateFormat="dd/mm/yy" mask="99/99/9999" yearNavigator yearRange="1960:2022" showIcon 
                                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                                        <label htmlFor={`${name}.passport_issue`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Passport Issue Date*</label>
                                                    </span>
                                                </div>
                                            )} />
                                            <Field name={`${name}.passport_expiry`} validate={composeValidators(required)} render={({ input,meta }) => (
                                                <div className="p-field">
                                                    <span className="p-float-label" >
                                                        <Calendar id={`${name}.passport_expiry`} {...input} dateFormat="dd/mm/yy" mask="99/99/9999" yearNavigator yearRange="2021:2050" showIcon 
                                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                                        <label htmlFor={`${name}.passport_expiry`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Passport Expiry Date*</label>
                                                    </span>
                                                </div>
                                            )} />                                            
                                        </div>
                                    </Condition>
                                    </>
                                )) }
                            </FieldArray>                            
                            <div style={{marginBottom: '2em'}}>      
                                <Divider align="center">
                                    <span className="p-tag">Taxation Details</span>
                                </Divider>
                            </div>
                            <div className="p-field" style={{marginBottom: '2em'}}>
                                <Button label="Add Taxation" style={{width: '15em'}} onClick={() => push('taxation', undefined) } />
                            </div>
                            <FieldArray name="taxation">                                    
                                {({ fields }) => fields.map((name, index) => (
                                    <div className="p-formgroup-inline" key={name}>
                                        <div className="p-field" style={{marginTop: '0.5em'}}>
                                            <label className="p-checkbox-label">Taxation Details #{index + 1} :</label>
                                        </div>
                                        <Field name={`${name}.tax_country`} validate={composeValidators(required)} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label" style={{width: '16em'}}>
                                                    <Dropdown id={`${name}.tax_country`} {...input} options={countries} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                                    <label htmlFor={`${name}.tax_country`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Country*</label>
                                                </span>
                                            </div>
                                        )} />
                                        <Field name={`${name}.tax_number`} validate={composeValidators(required)} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label">                                            
                                                    <InputText id={`${name}.tax_number`} {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase} />
                                                    <label htmlFor={`${name}.tax_number`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Tax Identification Number*</label>
                                                </span>
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />
                                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => fields.remove(index)}/>                           
                                    </div>
                                )) }
                            </FieldArray>
                                                                                    
                            <Button type="submit" label="Submit" className="p-mt-2" disabled={submitting || pristine} />
                            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                        </form>
                    )} />
                </div>
            </div>
        </div>
    );
}