import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom'
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Steps } from 'primereact/steps';
import { Checkbox } from 'primereact/checkbox';
//import { Dialog } from 'primereact/dialog';
import  useToken from './useToken';
import './LoginForm.css';

const focusOnError = createDecorator()

export const RegisterDetails = () => {    
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrency] = useState([]);
    //const [showMessage, setShowMessage] = useState(false);
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(1);    
    const { userInfo, getToken } = useToken();
    
    const items = [        
        {
            label: 'UserId creation'
            
        },
        {
            label: 'Personal'
            
        },
        {
            label: 'KYC details'
        },
        {
            label: 'Upload Documents'
        }
    ];

    const incomeSource = ['Gifts/Inheritence','Lifetime Earnings/Salary','Investment Profits','Sale of Assets'];    
    const employmentSource = ['Salaried', 'Business','Retired'];
    const pepOptions = ['Yes','No'];
    const fatcaOptions = ['Yes','No'];

    if(!userInfo) {
        navigate('/Login');        
    }
    
    useEffect(() => {
        fetch('/raffles/get_countries_array.php')
        .then(result => result.json())
        .then(countryData => setCountries(countryData))

        fetch('/raffles/get_currency.php')
        .then(result => result.json())
        .then(currencyData => setCurrency(currencyData))
        }, []);

    const validate = (data) => {
        let errors = {};

        if (!data.birth_country) {
            errors.birth_country = 'Country of Birth is required.';
        }

        if (!data.birth_date) {
            errors.birth_date = 'Date of Birth is required.';
        }

        if (!data.birth_place) {
            errors.birth_place = 'Place of Birth is required.';
        }

        if (!data.address) {
            errors.address = 'Address is required.';
        }
        
        if (!data.city) {
            errors.city = 'City is required.';
        }

        if (!data.pincode) {
            errors.pincode = 'Pincode is required.';
        }                
        else if (!/^[0-9]+$/i.test(data.pincode)) {
            errors.pincode = 'Invalid number in Pincode';
        }

        if (!data.country) {
            errors.country = 'Country is required.';
        }

        if (data.addressOption === false) {
            if (!data.address1) {
                errors.address1 = 'Address is required.';
            }
            
            if (!data.city1) {
                errors.city1 = 'City is required.';
            }
    
            if (!data.pincode1) {
                errors.pincode1 = 'Pincode is required.';
            }                
            else if (!/^[0-9]+$/i.test(data.pincode1)) {
                errors.pincode1 = 'Invalid number in Pincode';
            }
    
            if (!data.country1) {
                errors.country1 = 'Country is required.';
            }
        }

        if (!data.income_source) {
            errors.income_source = 'Income Source is required.';
        }

        if (!data.currency) {
            errors.currency = 'Currency is required.';
        }

        if (!data.annual_income) {
            errors.annual_income = 'Annual Income is required.';
        }
        else if (!/^[0-9.]+$/i.test(data.annual_income)) {
            errors.annual_income = 'Invalid number in Annual Income';
        }
        
        if (!data.net_worth) {
            errors.net_worth = 'Net Worth is required.';
        }
        else if (!/^[0-9.]+$/i.test(data.net_worth)) {
            errors.net_worth = 'Invalid number in Net Worth';
        }

        if (!data.employment_info) {
            errors.employment_info = 'Employment Details is required.';
        }

        if (!data.company_name) {
            errors.company_name = 'Company Name is required.';
        }

        if (!data.company_position) {
            errors.company_position = 'Company Position is required.';
        }

        if (!data.company_address) {
            errors.company_address = 'Company Address is required.';
        }

        if (!data.user_pep) {
            errors.user_pep = 'PEP is required.';
        }

        if (!data.user_relative_pep) {
            errors.user_relative_pep = 'Relatives PEP is required.';
        }

        if (!data.user_sanction) {
            errors.user_sanction = 'Sanction Info is required.';
        }

        if (!data.fatca) {
            errors.fatca = 'Identity Source is required.';
        }

        return errors;
    };

    const onSubmit = async(data, form) => {
        data.userid=userInfo.userid;
        data.birth_date = new Date(Date.UTC(data.birth_date.getFullYear(), data.birth_date.getMonth(), data.birth_date.getDate()))
        //console.log(JSON.stringify(data));   
        const response = await fetch('/raffles/registerdetails.php', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
            "Content-type": "application/json; charset=UTF-8"
            }
            }
        );

        var userResponse = await response.json();
        
        if (userResponse.message === "Success") {         
            navigate('/RegisterKYC')
        }
    };

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };
    
    const Condition = ({ when, is, children }) => (
        <Field name={when} subscription={{ value: true }}>
          {({ input: { value } }) => (value === is ? children : null)}
        </Field>        
    )
 
    //const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false) }/></div>;
      
       
    return (
        <div className="register-form">
            {/* <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">                    
                    <RegisterResponse  />
                </div>
            </Dialog> */}
            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h5 className="p-text-center">Registration</h5>
                    {/* <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} /> */}
                    <Steps model={items} activeIndex={activeIndex} readOnly={true} />

                    <Form 
                        onSubmit={onSubmit}                        
                        decorators={[focusOnError]} 
                        initialValues={{ userid: '', birth_date:'', birth_place:'',  birth_country:null, address: '',  city: '', pincode: '', country: null, addressOption:true, 
                            address1: '',  city1: '', pincode1: '', country1: null, income_source: null,  currency: null, annual_income: '', net_worth: '',
                            employment_info:null, company_name:'', company_position:'', company_address:'', user_pep:false, user_relative_pep:false, user_sanction:false,
                            fatca:false}} 
                        validate={validate} 
                        render={({ handleSubmit, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit} className="p-fluid">
                            <div style={{marginBottom: '1.5em'}}>
                                <Divider align="center">
                                    <span className="p-tag">Birth Details</span>
                                </Divider>
                            </div>
                            <div className="p-formgroup-inline">                                                              
                                <Field name="birth_date" render={({ input,meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <Calendar id="birth_date" {...input} autoFocus dateFormat="dd/mm/yy" mask="99/99/9999" timeZone="HongKong" yearNavigator yearRange="1960:2022" showIcon 
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/>
                                            <label htmlFor="birth_date" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Date of Birth</label>
                                        </span>
                                    </div>
                                )} />
                                <Field name="birth_place" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label p-input-icon-right">
                                            <i className="pi" />
                                            <InputText id="birth_place" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase} />
                                            <label htmlFor="birth_place" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Place of Birth</label>
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="birth_country" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <Dropdown id="birth_country" {...input} options={countries} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <label htmlFor="birth_country" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Country*</label>
                                        </span>
                                    </div>
                                )} />                               
                            </div>
                            <div style={{marginBottom: '1.5em'}}>
                                <Divider align="center">
                                    <span className="p-tag">Address Details</span>
                                </Divider>
                            </div>
                            <Field name="address" render={({ input, meta }) => (
                                <div className="p-field">
                                    <span className="p-float-label">
                                        <InputText id="address" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                        <label htmlFor="address" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Address*</label>   
                                    </span>
                                    {getFormErrorMessage(meta)}
                                </div>
                            )} />
                            <div className="p-formgroup-inline">
                                <Field name="city" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="city" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="city" className={classNames({ 'p-error': isFormFieldValid(meta) })}>City*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="pincode" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="pincode" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <label htmlFor="pincode" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Pincode*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="country" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <Dropdown id="country" {...input} options={countries} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <label htmlFor="country" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Country*</label>
                                        </span>
                                    </div>
                                )} />
                            </div>
                            <Field name="addressOption" type="checkbox" render={({ input, meta }) => (
                                <div className="p-field-checkbox">
                                    <label htmlFor="addressOption" className="p-checkbox-label">Correspondence address same as above address :&nbsp;&nbsp;</label>
                                    <Checkbox inputId="addressOption" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />                                    
                                </div>
                            )} />
                            <Condition when="addressOption" is={false}>                            
                                <Field name="address1" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="address1" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="address1" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Address*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <div className="p-formgroup-inline">                                    
                                    <Field name="city1" render={({ input, meta }) => (
                                        <div className="p-field">
                                            <span className="p-float-label">
                                                <InputText id="city1" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                                <label htmlFor="city1" className={classNames({ 'p-error': isFormFieldValid(meta) })}>City*</label>   
                                            </span>
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    <Field name="pincode1" render={({ input, meta }) => (
                                        <div className="p-field">
                                            <span className="p-float-label">
                                                <InputText id="pincode1" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                                <label htmlFor="pincode1" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Pincode*</label>   
                                            </span>
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    <Field name="country1" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <Dropdown id="country1" {...input} options={countries} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <label htmlFor="country1" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Country*</label>
                                        </span>
                                    </div>
                                    )} />                                    
                                </div>
                            </Condition>                           
                            <div style={{marginBottom: '1.5em'}}>
                                <Divider align="center">
                                    <span className="p-tag">Income Details</span>
                                </Divider>
                            </div>
                            <div className="p-formgroup-inline">
                                <Field name="income_source" render={({ input,meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <Dropdown id="income_source" {...input} options={incomeSource}
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/>
                                            <label htmlFor="income_source" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Income Source</label>
                                        </span>
                                    </div>
                                )} />
                                <Field name="currency" render={({ input,meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <Dropdown id="currency" {...input} options={currencies}
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/>
                                            <label htmlFor="currency" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Currency</label>
                                        </span>
                                    </div>
                                )} />
                                <Field name="annual_income" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="annual_income" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/>
                                            <label htmlFor="annual_income" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Annual Income*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />                            
                                <Field name="net_worth" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="net_worth" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <label htmlFor="net_worth" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Net Worth*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                            </div>
                            <div style={{marginBottom: '1.5em'}}>
                                <Divider align="center">
                                    <span className="p-tag">Work Experience</span>
                                </Divider>
                            </div>
                            <Field name="employment_info" render={({ input,meta }) => (
                                <div className="p-field">
                                    <span className="p-float-label" style={{width: '16em'}}>
                                        <Dropdown id="employment_info" {...input} options={employmentSource}
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/> 
                                        <label htmlFor="employment_info" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Work Experience</label>
                                    </span>
                                </div>
                            )} />
                            <div className="p-formgroup-inline">
                                <Field name="company_name" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <InputText id="company_name" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="company_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Comnpany Name*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="company_position" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <InputText id="company_position" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="company_position" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Position*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="company_address" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '32em'}}>
                                            <InputText id="company_address" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="company_address" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Address*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                            </div>                            
                            <div style={{marginBottom: '1.5em'}}>
                                <Divider align="center">
                                    <span className="p-tag">Politically Exposed Persons (PEP) / Sanctions</span>
                                </Divider>
                            </div>
                            <div className="p-formgroup-inline">
                                <Field name="user_pep" render={({ input,meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <Dropdown id="user_pep" {...input} options={pepOptions} 
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/> 
                                            <label htmlFor="user_pep" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Is Applicant a PEP*</label>
                                        </span>
                                    </div>
                                )} />
                                <Field name="user_relative_pep" render={({ input,meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <Dropdown id="user_relative_pep" {...input} options={pepOptions} 
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/> 
                                            <label htmlFor="user_relative_pep" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Is Applicant's Relative a PEP*</label>
                                        </span>
                                    </div>
                                )} />
                                <Field name="user_sanction" render={({ input,meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '16em'}}>
                                            <Dropdown id="user_sanction" {...input} options={pepOptions} 
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/> 
                                            <label htmlFor="user_sanction" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Is Applicant on any Sanction list*</label>
                                        </span>
                                    </div>
                                )} />
                            </div>                             
                            <Field name="fatca" render={({ input,meta }) => (
                                <div className="p-field">
                                    <span className="p-float-label" style={{width: '20em'}}>
                                        <Dropdown id="fatca" {...input} options={fatcaOptions} 
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/> 
                                        <label htmlFor="fatca" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Is Applicant subjected to US Tax</label>
                                    </span>
                                </div>
                            )} />
                            <Button type="submit" label="Submit" className="p-mt-2" disabled={submitting || pristine} />
                            {/* <pre>{JSON.stringify(values, 0, 2)}</pre>  */}
                        </form>
                    )} />
                </div>
            </div>
        </div>
    );
}