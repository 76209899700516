import React, { useEffect, useState, useRef } from 'react';
import { useNavigate} from 'react-router-dom'
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus'
import { InputText } from 'primereact/inputtext';
//import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import './LoginForm.css';

const focusOnError = createDecorator()

export const RegisterForm = () => {
    const [countries, setCountries] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    
    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef(null);
    const titles = ['Mr.','Mrs.','Ms.','Dr.'];

    const items = [        
        {
            label: 'UserId creation',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'UserId creation', detail: event.item.label });
            }
        },
        {
            label: 'Personal',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Personal Info', detail: event.item.label });
            }
        },
        {
            label: 'KYC details',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'KYC details', detail: event.item.label });
            }
        },
        {
            label: 'Upload Documents',
            command: (event) => {
                toast.current.show({ severity: 'info', summary: 'Upload Documents', detail: event.item.label });
            }
        }
    ];

    useEffect(() => {
        fetch('/raffles/get_countries.php')
        .then(result => result.json())
        .then(countryData => setCountries(countryData))
        }, []);

    const validate = (data) => {
        let errors = {};

        if (!data.user_title) {
            errors.user_title = 'Title is required.';
        }

        if (!data.first_name) {
            errors.first_name = 'First Name is required.';
        }
        else if (!/^[a-zA-Z][a-zA-Z]+$/i.test(data.first_name)) {
            errors.first_name = 'Invalid character in First Name';
        }

        if (!data.country) {
            errors.country = 'Country is required.';
        } 

        if (!data.phone) {
            errors.phone = 'Phone is required.';
        }
        else if (!/^[0-9]+$/i.test(data.phone)) {
            errors.phone = 'Invalid number in Phone';
        }

        if (!data.email) {
            errors.email = 'Email is required.';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
            errors.email = 'Invalid email address. E.g. example@email.com';
        }

        if (!data.userid) {
            errors.userid = 'UserId is required.';
        }

        if (!data.password) {
            errors.password = 'Password is required.';
        }

        if (!data.confirm_password) {
            errors.confirm_password = 'Password is required.';
        }

        if (data.password !== data.confirm_password) {
            errors.confirm_password = "Passwords don't match.";
        }

               

        if (!data.accept) {
            errors.accept = 'You need to agree to the terms and conditions.';
        }

        return errors;
    };

    const onSubmit = async(data, form) => {        
        const response = await fetch('/raffles/register.php', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            }
            }
        );

        var userResponse = await response.json();
        //console.log(userResponse.userid);
        setFormData(userResponse);
        setShowMessage(true);        
    };

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };

    function RegisterResponse() {        
        if (formData.message === "Success") {
            return  (
                <>
                <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                <h5>Registration Successful!</h5>
                <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                    Your account is registered under name <b>{formData.name}</b>. Please login for completing Registration process.
                </p>
                </>
            );  
        }
        else {
            return  (
                <>
                <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                <h5>Registration Failed!</h5>
                <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                    <b>{formData.message}</b>
                </p>
                </>
            );
        }
    }

    function onShowMessageClick() {
        setShowMessage(false);
        if (formData.message === "Success") {         
            navigate('/Login')
        }
    }

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={onShowMessageClick } /></div>;
    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="p-mt-2">Suggestions</p>
            <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="register-form">
            <Toast ref={toast}></Toast>
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">                    
                    <RegisterResponse  />
                </div>
            </Dialog>

            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h5 className="p-text-center">Registration</h5>
                    {/* <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} /> */}
                    <Steps model={items} activeIndex={activeIndex} readOnly={true} />

                    <Form onSubmit={onSubmit} 
                        decorators={[focusOnError]}
                        initialValues={{ user_title: null, first_name:'', middle_name:'', last_name:'', phone: '', email: '', userid: '', password: '', confirm_password: '', 
                                            country: null, accept: false }} 
                        validate={validate} 
                        render={({ handleSubmit, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit} className="p-fluid">
                            <div className="p-formgroup-inline">
                                <Field name="user_title" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '5em'}}>
                                            <Dropdown id="user_title" {...input} autoFocus options={titles} className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/>
                                            <label htmlFor="user_title" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Title*</label>
                                        </span>
                                    </div>
                                )} />                  
                                <Field name="first_name" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="first_name" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="first_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>First Name*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="middle_name" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="middle_name" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="middle_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Middle Name</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="last_name" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="last_name" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="last_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Last Name</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                            </div>
                            <div style={{marginBottom: '1.5em'}}>
                                <Divider align="center">
                                    <span className="p-tag">Contact Details</span>
                                </Divider>
                            </div>
                            <div className="p-formgroup-inline">
                                <Field name="country" render={({ input, meta }) => (
                                        <div className="p-field">
                                            <span className="p-float-label" style={{width: '16em'}}>
                                                <Dropdown id="country" {...input} options={countries} optionLabel="name" className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                                <label htmlFor="country" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Country*</label>
                                            </span>
                                        </div>
                                )} />                                
                                <Field name="phone" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label p-input-icon-right">
                                            <i className="pi pi-phone" />
                                            <InputText id="phone" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <label htmlFor="phone" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Phone without country code*</label>
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />                               
                                <Field name="email" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label p-input-icon-right">
                                            <i className="pi pi-envelope" />
                                            <InputText id="email" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Email*</label>
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                            </div>
                            <div style={{marginBottom: '1.5em'}}>
                                <Divider align="center">
                                    <span className="p-tag">User Creation</span>
                                </Divider>
                            </div>
                            <div className="p-formgroup-inline">
                                <Field name="userid" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label p-input-icon-right">
                                            <i className="pi pi-user" />
                                            <InputText id="userid" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })}  onInput={toInputUppercase}/>
                                            <label htmlFor="userid" className={classNames({ 'p-error': isFormFieldValid(meta) })}>UserId*</label>
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="password" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <Password id="password" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} header={passwordHeader} footer={passwordFooter} />
                                            <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Password*</label>
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="confirm_password" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <Password id="confirm_password" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} header={passwordHeader} footer={passwordFooter} />
                                            <label htmlFor="confirm_password" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Confirm Password*</label>
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                            </div>
                            <Field name="accept" type="checkbox" render={({ input, meta }) => (
                                <div className="p-field-checkbox">
                                    <Checkbox inputId="accept" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                    <label htmlFor="accept" className={classNames({ 'p-error': isFormFieldValid(meta) })}>I agree to the terms and conditions*</label>
                                </div>
                            )} />

                            <Button type="submit" label="Submit" className="p-mt-2" disabled={submitting || pristine} />
                            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                        </form>
                    )} />
                </div>
            </div>
        </div>
    );
}