import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate} from 'react-router-dom'
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';

import { classNames } from 'primereact/utils';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import  useToken from './useToken';
import './LoginForm.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const focusOnError = createDecorator()

export const CreatePortfolio = () => {    
    const [secData, setSecData] = useState(null);    
    const [secRowData, setSecRowData] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [validationType, setValidationType] = useState(0);
    const navigate = useNavigate();
    const { userInfo, getToken } = useToken();

    const secInfo = {stk_symbol:"", stk_exch:""};

    useEffect(() => {
        fetch('/raffles/get_securities.php')
        .then(result => result.json())
        .then(secData => setSecData(secData))
    }, []);

    /* const marketGridHeader = (
        <div className="table-header">
            NIFTY 200            
        </div>
    ); */

   
    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}                
            </>
        );
    };

    const numberTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <div style={{ textAlign: 'right' }}>{numberParser(data[props.field])}</div>
            </>
        );
    };
    
    function numberParser(value) {          
        var valueAsNumber;
        if (value === null || value === undefined || value === '') {
          valueAsNumber = 0;
        } else {
          valueAsNumber = parseFloat(value);
        }
        return valueAsNumber;
    }

    function setSecFields() {       
        secInfo.stk_token=secRowData.stk_token; 
        secInfo.stk_symbol=secRowData.stk_symbol; 
        secInfo.stk_exch=secRowData.stk_exch;
    }

    const validate = (data) => {
        let errors = {};       

        if (!data.portfolio_name) {
            errors.portfolio_name = 'Portfolio Name is required.';
        }

        if (!data.portfolio_desc) {
            errors.portfolio_desc = 'Portfolio Description is required.';
        }

        if (!data.min_investment) {
            errors.min_investment = 'Minimum Investment is required.';
        }

        if (!data.portfolio_type) {
            errors.portfolio_type = 'Portfolio Type is required.';
        }

        if (!data.portfolio_region) {
            errors.portfolio_region = 'Portfolio Region is required.';
        }

        return errors;
    };

    const onSubmit = async(data, form) => {
        var validationOk=false;
        
        setValidationType(0);  
       
        
        if (data.Stocks) {
            if (data.Stocks.length > 0) {
                validationOk=true;
            }                
        }

        if (!validationOk) {
            setValidationType(1);
            setShowMessage(true);
            return;
        }
        
        if (validationOk) {
            data.userid=userInfo.userid;
            console.log(JSON.stringify(data));
                      
            const response = await fetch('/raffles/createPortfolio.php', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                "Content-type": "application/json; charset=UTF-8"
                }
                }
            );

            var userResponse = await response.json();
            
            setFormData(userResponse);
            
            setShowMessage(true); 
        }
    };       
   
    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };
   

    function PortfolioResponse() {   
        if (validationType === 0) {     
            if (formData.message === "Success") {
                return  (
                    <>
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Portfolio Creation Successful!</h5>                    
                    </>
                );  
            }
            else {
                return  (
                    <>
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Portfolio Creation Failed!</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        <b>{formData.message}</b>
                    </p>
                    </>
                );
            }
        }
        else if (validationType === 1) {
            return  (
                <div><h4>Please click on Add Stocks</h4></div>
            );
        }
    }

    function onShowMessageClick() {
        setShowMessage(false);
        if (formData.message === "Success") {         
            navigate('/ViewPortfolio')
        }
    }

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={onShowMessageClick } /></div>;
    const required = (value) => (value ? undefined : "Required");
    const composeValidators = (...validators) => (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

    return (
        <div className="register-form">                   
            
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">                    
                    <PortfolioResponse  />
                </div>
            </Dialog>

            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h5 className="p-text-center">Portfolio Creation</h5>                    

                    <Form 
                        onSubmit={onSubmit} 
                        decorators={[focusOnError]}
                        mutators={{
                            ...arrayMutators
                          }}
                        initialValues={{ userid: '',  
                            portfolio_name:'', portfolio_desc:'', clientid:'ALL', min_investment:'', portfolio_type:'', portfolio_region:''}} 
                        validate={validate} 
                        render={({ handleSubmit, submitting, pristine,
                            form: {
                                mutators: { push, pop }
                            }, // injected from final-form-arrays above
                            values }) => (
                        <form onSubmit={handleSubmit} className="p-fluid">
                            <div style={{marginBottom: '2em'}}>
                                <Divider align="center">
                                    <span className="p-tag">Portfolio Details</span>
                                </Divider>
                            </div>
                            <div className="p-formgroup-inline">
                                <Field name="portfolio_name" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <InputText id="portfolio_name" {...input} autoFocus className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="portfolio_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Portfolio Name</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="portfolio_desc" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <InputText id="portfolio_desc" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="portfolio_desc" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Portfolio Desc</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="clientid" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <InputText id="clientid" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="clientid" className={classNames({ 'p-error': isFormFieldValid(meta) })}>ClientId</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="min_investment" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="min_investment" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="min_investment" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Minimum Investment</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />                                
                            </div>
                            <div className="p-formgroup-inline">
                                <Field name="portfolio_type" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <InputText id="portfolio_type" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="portfolio_type" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Portfolio Type</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="portfolio_region" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label">
                                            <InputText id="portfolio_region" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="portfolio_region" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Portfolio Region</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />                                
                            </div>                            
                            <div style={{marginBottom: '2em'}}>      
                                <Divider align="center">
                                    <span className="p-tag">Security Details</span>
                                </Divider>
                            </div>
                            <div style={{ width: '100%', height: '300px' }} className="p-grid table-demo">
                                <div className="p-col-12">
                                    <DataTable value={secData} scrollable scrollHeight="250px" className="p-datatable-customers" 
                                        selectionMode="single" selection={secRowData} onSelectionChange={e => setSecRowData(e.value)}
                                        /*
                                        selectionMode="single" contextMenuSelection={secRowData} onContextMenuSelectionChange={e => setSecRowData(e.value) }
                                        onContextMenu={e => cm.current.show(e.originalEvent)} responsiveLayout="scroll" */
                                        emptyMessage="No Portfolio found." >                        
                                        <Column field="stk_name" header="Name" headerStyle={{ width: '16em' }} sortable body={bodyTemplate}></Column>
                                        <Column field="stk_exch" header="Exch" headerStyle={{ width: '8em' }} sortable body={bodyTemplate}></Column>
                                        <Column field="stk_symbol" header="Symbol" sortable body={bodyTemplate} />
                                        <Column field="stk_type" header="Type" sortable body={bodyTemplate} /> 
                                        <Column field="region" header="Region" sortable body={bodyTemplate} />
                                        <Column field="currency" header="Currency" sortable body={bodyTemplate} />
                                        <Column field="open" header="Open"  headerStyle={{ width: '8em' }} body={numberTemplate}></Column>
                                        <Column field="high" header="High" headerStyle={{ width: '8em' }} body={numberTemplate}></Column>
                                        <Column field="low" header="Low" headerStyle={{ width: '8em' }} body={numberTemplate}></Column>
                                        <Column field="close" header="Close" headerStyle={{ width: '8em' }} body={numberTemplate}></Column>
                                        <Column field="adjusted_close" header="Adj Close" headerStyle={{ width: '8em' }} body={numberTemplate}></Column>
                                        <Column field="volume" header="Volume" headerStyle={{ width: '8em' }} body={numberTemplate}></Column>                                        
                                    </DataTable>                                
                                </div>
                            </div>
                            <div className="p-field" style={{marginTop: '2em', marginBottom: '2em'}}>
                                <Button label="Add Security" style={{width: '15em'}} onClick={() => ( setSecFields(), push('Stocks', secInfo) ) } />
                            </div>
                            <FieldArray name="Stocks">                                    
                                {({ fields }) => fields.map((name, index) => (
                                    <>                                    
                                    <div className="p-formgroup-inline" key={name}>
                                        <div className="p-field" style={{marginTop: '0.5em'}}>
                                            <label className="p-checkbox-label">Security Details #{index + 1} :</label>
                                        </div>
                                        <Field name={`${name}.stk_symbol`} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label">
                                                    <InputText id={`${name}.stk_symbol`} {...input} readOnly className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                                    <label htmlFor={`${name}.stk_symbol`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Security</label>   
                                                </span>
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />                                     
                                        <Field name={`${name}.stk_perc`} validate={composeValidators(required)} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label" style={{width: '12em'}}>                                            
                                                    <InputText id={`${name}.stk_perc`} {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase} />
                                                    <label htmlFor={`${name}.stk_perc`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Stock Perc*</label>
                                                </span>
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />
                                        <Field name={`${name}.lower_range_perc`} validate={composeValidators(required)} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label" style={{width: '12em'}}>                                            
                                                    <InputText id={`${name}.lower_range_perc`} {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase} />
                                                    <label htmlFor={`${name}.lower_range_perc`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Lower Range Perc*</label>
                                                </span>
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />
                                        <Field name={`${name}.higher_range_perc`} validate={composeValidators(required)} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label" style={{width: '12em'}}>                                            
                                                    <InputText id={`${name}.higher_range_perc`} {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase} />
                                                    <label htmlFor={`${name}.higher_range_perc`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Higher Range Perc*</label>
                                                </span>
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />                                        
                                    {/* </div>
                                    <div className="p-formgroup-inline"> */}
                                        <Field name={`${name}.min_investment`} validate={composeValidators(required)} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label" style={{width: '12em'}}>                                            
                                                    <InputText id={`${name}.min_investment`} {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase} />
                                                    <label htmlFor={`${name}.min_investment`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Minimum Investment*</label>
                                                </span>
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />                                        
                                        <Field name={`${name}.stoploss_price`} validate={composeValidators(required)} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label" style={{width: '12em'}}>                                            
                                                    <InputText id={`${name}.stoploss_price`} {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase} />
                                                    <label htmlFor={`${name}.stoploss_price`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>StopLoss Price*</label>
                                                </span>
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />
                                        <Field name={`${name}.target_price`} validate={composeValidators(required)} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label" style={{width: '12em'}}>                                            
                                                    <InputText id={`${name}.target_price`} {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase} />
                                                    <label htmlFor={`${name}.target_price`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Target Price*</label>
                                                </span>
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />

                                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => fields.remove(index)}/>
                                    </div>
                                    </>
                                )) }
                            </FieldArray>
                                                                                    
                            <Button type="submit" label="Submit" className="p-mt-2" disabled={submitting || pristine} />
                            {/* <pre>{JSON.stringify(values, 0, 2)}</pre>  */}
                        </form>
                    )} />
                </div>
            </div>
        </div>
    );
}