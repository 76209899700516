import React, { useEffect, useState, useRef  } from 'react';
import { Link} from 'react-router-dom'
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import  useToken from './useToken';
import './LoginForm.css';

export const ViewPortfolio = () => {    
    const [portfolioData, setPortfolioData] = useState(null);    
    const [portfolioRowData, setPortfolioSecRowData] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const { userInfo, getToken } = useToken();
    const cm = useRef(null);
    
    const [filters1, setFilters1] = useState({
        'client_id': { value: null, matchMode: FilterMatchMode.STARTS_WITH }
    });

    const menuModel = [
        {label: 'Activate', icon: 'pi pi-fw pi-search', command: () => activatePortfolio(portfolioRowData)},
        {label: 'Suspend', icon: 'pi pi-fw pi-times', command: () => suspendPortfolio(portfolioRowData)}
    ];

    useEffect(() => {
        fetch('/raffles/get_portfolio.php')
        .then(result => result.json())
        .then(portfolioData => setPortfolioData(portfolioData))
    }, []);

    const portfolioGridHeader = (
        <div className="table-header">
            Portfolio Master           
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}                
            </>
        );
    };

    const numberTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <div style={{ textAlign: 'right' }}>{numberParser(data[props.field])}</div>
            </>
        );
    };
    
    function numberParser(value) {          
        var valueAsNumber;
        if (value === null || value === undefined || value === '') {
          valueAsNumber = 0;
        } else {
          valueAsNumber = parseFloat(value);
        }
        return valueAsNumber;
    }

    const activatePortfolio = async(portfolioRowData) => {
        let request = {userid:''};

        request.userid = userInfo.userid;
        request.portfolio_id=portfolioRowData.portfolio_id;
        request.status="ACTV";

        const response = await fetch('/raffles/upd_portfolio_status.php', {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
            "Content-type": "application/json; charset=UTF-8"
            }
            }
        );

        var userResponse = await response.json();

        if (userResponse.message === "Success") {
            let _portfolioData = [...portfolioData];

            for (let i = 0; i < portfolioData.length; i++) {
                if (_portfolioData[i].portfolio_id === portfolioRowData.portfolio_id) {
                    _portfolioData[i].portfolio_status="ACTV";
                    break;
                }
            }
            setPortfolioData(_portfolioData);
        }        
    }

    const suspendPortfolio = async(portfolioRowData) => {        
        let request = {userid:''};
        
        request.userid = userInfo.userid;
        request.portfolio_id=portfolioRowData.portfolio_id;
        request.status="DACT";

        const response = await fetch('/raffles/upd_portfolio_status.php', {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
            "Content-type": "application/json; charset=UTF-8"
            }
            }
        );

        
        var userResponse = await response.json();        
        if (userResponse.message === "Success") {
            let _portfolioData = [...portfolioData];

            for (let i = 0; i < portfolioData.length; i++) {
                if (_portfolioData[i].portfolio_id === portfolioRowData.portfolio_id) {
                    _portfolioData[i].portfolio_status="DACT";
                    break;
                }
            }

            setPortfolioData(_portfolioData);
        }        
    }


    const expandAll = () => {
        let _expandedRows = {};
        portfolioData.forEach(p => _expandedRows[`${p.id}`] = true);

        setExpandedRows(_expandedRows);
    }

    const collapseAll = () => {
        setExpandedRows(null);
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable" style={{ width: '100%' }}>
                {/* <h5>Securities for {data.portfolio_name}</h5>             */}
                <DataTable value={data.details} responsiveLayout="scroll">
                    <Column field="stk_name" header="Name" style={{ width: '20em' }} body={bodyTemplate} sortable ></Column>
                    <Column field="stk_exch" header="Exch" style={{ width: '10em' }} body={bodyTemplate} sortable ></Column>
                    <Column field="stk_perc" header="Perc" style={{ width: '10em' }} sortable></Column>
                    <Column field="low_range_perc" header="Lower Perc" style={{ width: '10em' }} body={numberTemplate}></Column>
                    <Column field="high_range_perc" header="Higher Perc" style={{ width: '10em' }} body={numberTemplate}></Column>
                    <Column field="min_investment" header="Min Investment" style={{ width: '10em' }} body={numberTemplate}></Column>
                    <Column field="stoploss_price" header="Stoploss Price" style={{ width: '10em' }} body={numberTemplate}></Column>
                    <Column field="target_price" header="Target Price" style={{ width: '10em' }} body={numberTemplate}></Column>
                </DataTable>
            </div>
        );
    }

    const header = (
        <div className="table-header-container">
            <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} className="p-mr-2" />
            <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} />
        </div>
    );


    return (
        <div className="register-form">
            <ContextMenu model={menuModel} ref={cm} onHide={() => setPortfolioSecRowData(null)}/>
            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h5 className="p-text-center">Portfolio Master</h5>
                    <div style={{marginBottom: '2em'}}>
                        <Divider align="center">
                            <span className="p-tag">Portfolio Creation</span>
                        </Divider>
                        <div className="p-col-12 p-d-flex p-ai-center p-jc-center">                            
                            <Link to="/CreatePortfolio">
                                <Button label="Create Portfolio" icon="pi pi-calendar-plus" className="p-button-success"></Button>
                            </Link>                            
                        </div>
                        <Divider align="center">
                            <span className="p-tag">Portfolio Details</span>
                        </Divider>
                        <div style={{ width: '100%', minheight: '370px' }} className="p-grid table-demo">
                            <div className="p-col-12">
                                <DataTable value={portfolioData} scrollable scrollHeight="550px" className="p-datatable-customers"
                                    filters={filters1} filterDisplay="row" globalFilterFields={['client_id']}
                                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} 
                                    //selectionMode="single" selection={portfolioRowData} onSelectionChange={e => setPortfolioSecRowData(e.value)}
                                    
                                    selectionMode="single" contextMenuSelection={portfolioRowData} onContextMenuSelectionChange={e => setPortfolioSecRowData(e.value) }
                                    onContextMenu={e => cm.current.show(e.originalEvent)} responsiveLayout="scroll"
                                    dataKey="portfolio_id"  emptyMessage="No Portfolios found." > 
                                    <Column expander style={{ width: '3em' }} />                      
                                    <Column field="portfolio_name" header="Name" style={{ width: '10em' }} sortable body={bodyTemplate}></Column>
                                    <Column field="portfolio_desc" header="Description" style={{ width: '20em' }}  body={bodyTemplate}></Column>
                                    <Column field="client_id" header="Client" filter filterPlaceholder="Search by Client" style={{ minWidth: '12rem' }} sortable body={bodyTemplate} />
                                    <Column field="min_investment" header="Min Investment"  style={{ width: '10em' }} body={numberTemplate}></Column>
                                    <Column field="portfolio_type" header="Type" body={bodyTemplate} />
                                    <Column field="portfolio_region" header="Region" body={bodyTemplate} />
                                    <Column field="portfolio_status" header="Status" body={bodyTemplate} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
