import React, { useState, useEffect, useRef } from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


export const AgGrid = () => {
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);

    useEffect(() => {
        fetch('/Market/get_watchview.php')
        .then(result => result.json())
        .then(rowData => setRowData(rowData))
        }, []);

    return (
        <div style={{ width: '100%', height: '600px' }}>
            <div id="myGrid" className="ag-theme-alpine" style={{ width: '100%', height: '600px' }}>        
            <AgGridReact rowHeight={40}
                ref={gridRef}
                rowData={rowData}>            
                <AgGridColumn field="stk_name" headerName="Name" maxWidth={180} sortable={ true } filter={ true } editable={false} pinned="left"/>
                {/* <AgGridColumn field="stk_me" headerName="ME" maxWidth={100} editable={false} /> */}
                <AgGridColumn field="open_price" headerName="Open" type="numericColumn" maxWidth={120} filter={ true } editable={false} />
                <AgGridColumn field="high_price" headerName="High" type="numericColumn" maxWidth={120} filter={ true } editable={false} />
                <AgGridColumn field="low_price" headerName="Low" type="numericColumn" maxWidth={120} filter={ true } editable={false} />
                <AgGridColumn field="close_price" headerName="Close" type="numericColumn" maxWidth={120} filter={ true } editable={false} />
                <AgGridColumn field="net_perc"  headerName="Net Perc" type="numericColumn" maxWidth={120} filter={ true } editable={false} />
                <AgGridColumn field="rsi" headerName="RSI" type="numericColumn" sortable={ true } filter={true} maxWidth={120} editable={false}
                    valueParser={numberParser}             
                    cellClassRules={{
                    'rag-green': 'x < 30',                
                    'rag-red': 'x > 75',
                    }} />
                <AgGridColumn field="sma" headerName="SMA" type="numericColumn" filter={ true } maxWidth={120} editable={false} />
                <AgGridColumn field="sma24" headerName="SMA-24" type="numericColumn" filter={ true } maxWidth={120} editable={false} />
                <AgGridColumn field="sma200" headerName="SMA-200" type="numericColumn" filter={ true } maxWidth={120} editable={false}               
                cellStyle={sma200style} />
            </AgGridReact>
            </div>
        </div>       
    )

    function numberParser(params) {  
        var newValue = params.newValue;
        var valueAsNumber;
        if (newValue === null || newValue === undefined || newValue === '') {
          valueAsNumber = null;
        } else {
          valueAsNumber = parseFloat(params.newValue);
        }
        return valueAsNumber;
      }
      
      function sma200style(params) { 
        var sma = parseFloat(params.node.data.sma);
        var close = parseFloat(params.node.data.close_price);
        var sma200 = parseFloat(params.value);
      
        if (sma >= sma200 && close <= sma200) {
          // console.log({
          //   "sma": sma,
          //   "sma200": sma200,
          //   "close": close
          // });
          return {color: 'white', backgroundColor: 'green'};    
        }
      
        return null;
      }
    }
