import React,{ useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function withNavigation(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
  }
  
  function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
  }

  
const ScrollToTop = (props) => {

    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    return props.children;
}

export default withNavigation(ScrollToTop);
