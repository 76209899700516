import React, { useState, useEffect, useRef } from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


export const SecuritiesInfo = () => {
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);

    useEffect(() => {
        fetch('/raffles/get_securities.php')
        .then(result => result.json())
        .then(rowData => setRowData(rowData))
        }, []);

    return (
        <div style={{ width: '100%', height: '600px' }}>
            <div id="myGrid" className="ag-theme-alpine" style={{ width: '100%', height: '400px' }}>        
            <AgGridReact rowHeight={40}
                rowSelection={'single'}
                ref={gridRef}
                rowData={rowData}>           

                <AgGridColumn field="stk_name" headerName="Name" maxWidth={250} sortable={ true } filter={ true } editable={false} pinned="left"/>
                <AgGridColumn field="stk_exch" headerName="Exch" maxWidth={100} editable={false} />
                <AgGridColumn field="stk_symbol" headerName="Symbol" maxWidth={100} editable={false} />
                <AgGridColumn field="stk_type" headerName="Type" maxWidth={100} editable={false} />
                <AgGridColumn field="region" headerName="Region" maxWidth={100} editable={false} />
                <AgGridColumn field="currency" headerName="Currency" maxWidth={100} editable={false} />
                <AgGridColumn field="open" headerName="Open" type="numericColumn" maxWidth={120} filter={ true } editable={false} />
                <AgGridColumn field="high" headerName="High" type="numericColumn" maxWidth={120} filter={ true } editable={false} />
                <AgGridColumn field="low" headerName="Low" type="numericColumn" maxWidth={120} filter={ true } editable={false} />
                <AgGridColumn field="close" headerName="Close" type="numericColumn" maxWidth={120} filter={ true } editable={false} />
                <AgGridColumn field="adjusted_close"  headerName="Adj Close" type="numericColumn" maxWidth={120} filter={ true } editable={false} />
                <AgGridColumn field="volume"  headerName="Volume" type="numericColumn" maxWidth={120} filter={ true } editable={false} />
            </AgGridReact>
            </div>
        </div>       
    )

    function numberParser(params) {  
        var newValue = params.newValue;
        var valueAsNumber;
        if (newValue === null || newValue === undefined || newValue === '') {
          valueAsNumber = null;
        } else {
          valueAsNumber = parseFloat(params.newValue);
        }
        return valueAsNumber;
      }      
      
    }