import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate} from 'react-router-dom'
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';

import { classNames } from 'primereact/utils';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import  useToken from './useToken';
import './LoginForm.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const focusOnError = createDecorator()

export const CreateSubFund = () => {    
    const [cltData, setCltData] = useState(null);    
    const [cltRowData, setCltRowData] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [validationType, setValidationType] = useState(0);
    const navigate = useNavigate();
    const { userInfo, getToken } = useToken();

    const clientInfo = {user_id:"",client_id:"", user_name:""};
    const status = ['ACTV','DACT'];

    useEffect(() => {
        fetch('/raffles/get_clients.php')
        .then(result => result.json())
        .then(cltData => setCltData(cltData))
    }, []);

   
    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}                
            </>
        );
    };

    const numberTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <div style={{ textAlign: 'right' }}>{numberParser(data[props.field])}</div>
            </>
        );
    };
    
    function numberParser(value) {          
        var valueAsNumber;
        if (value === null || value === undefined || value === '') {
          valueAsNumber = 0;
        } else {
          valueAsNumber = parseFloat(value);
        }
        return valueAsNumber;
    }

    function setCltFields() {       
        clientInfo.user_id=cltRowData.user_id; 
        clientInfo.client_id=cltRowData.client_id; 
        clientInfo.user_name=cltRowData.user_name;
    }

    const validate = (data) => {
        let errors = {};       

        if (!data.sub_fund_id) {
            errors.sub_fund_id = 'Sub Fund Id is required.';
        }

        if (!data.sub_fund_name) {
            errors.sub_fund_name = 'Sub Fund Name is required.';
        }

        if (!data.sub_fund_status) {
            errors.sub_fund_status = 'Sub Fund Status is required.';
        }       

        return errors;
    };

    const onSubmit = async(data, form) => {
        var validationOk=false;
        
        setValidationType(0);  
       
        
        if (data.Clients) {
            if (data.Clients.length > 0) {
                validationOk=true;
            }                
        }

        if (!validationOk) {
            setValidationType(1);
            setShowMessage(true);
            return;
        }
        
        if (validationOk) {
            data.userid=userInfo.userid;
            console.log(JSON.stringify(data));
                      
            const response = await fetch('/raffles/createSubFund.php', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                "Content-type": "application/json; charset=UTF-8"
                }
                }
            );

            var userResponse = await response.json();
            
            setFormData(userResponse);
            
            setShowMessage(true); 
        }
    };       
   
    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };
   

    function SubFundResponse() {   
        if (validationType === 0) {     
            if (formData.message === "Success") {
                return  (
                    <>
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Sub Fund Creation Successful!</h5>                    
                    </>
                );  
            }
            else {
                return  (
                    <>
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Sub Fund Creation Failed!</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        <b>{formData.message}</b>
                    </p>
                    </>
                );
            }
        }
        else if (validationType === 1) {
            return  (
                <div><h4>Please click on Add Clients</h4></div>
            );
        }
    }

    function onShowMessageClick() {
        setShowMessage(false);
        if (formData.message === "Success") {         
            navigate('/ViewPortfolio')
        }
    }

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={onShowMessageClick } /></div>;
    const required = (value) => (value ? undefined : "Required");
    const composeValidators = (...validators) => (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

    return (
        <div className="register-form">                   
            
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">                    
                    <SubFundResponse  />
                </div>
            </Dialog>

            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h5 className="p-text-center">Sub Fund Creation</h5>                    

                    <Form 
                        onSubmit={onSubmit} 
                        decorators={[focusOnError]}
                        mutators={{
                            ...arrayMutators
                          }}
                        initialValues={{ userid: '', sub_fund_id:'', sub_fund_name:'', sub_fund_status:'ACTV'}} 
                        validate={validate} 
                        render={({ handleSubmit, submitting, pristine,
                            form: {
                                mutators: { push, pop }
                            }, // injected from final-form-arrays above
                            values }) => (
                        <form onSubmit={handleSubmit} className="p-fluid">
                            <div style={{marginBottom: '2em'}}>
                                <Divider align="center">
                                    <span className="p-tag">Sub Fund Details</span>
                                </Divider>
                            </div>
                            <div className="p-formgroup-inline">
                                <Field name="sub_fund_id" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <InputText id="sub_fund_id" {...input} autoFocus className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="sub_fund_id" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Sub Fund Id*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="sub_fund_name" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" >
                                            <InputText id="sub_fund_name" {...input}  className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                            <label htmlFor="sub_fund_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Sub Fund Name*</label>   
                                        </span>
                                        {getFormErrorMessage(meta)}
                                    </div>
                                )} />
                                <Field name="sub_fund_status" render={({ input, meta }) => (
                                    <div className="p-field">
                                        <span className="p-float-label" style={{width: '5em'}}>
                                            <Dropdown id="sub_fund_status" {...input} autoFocus options={status} className={classNames({ 'p-invalid': isFormFieldValid(meta) })}/>
                                            <label htmlFor="sub_fund_status" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Status*</label>
                                        </span>
                                    </div>
                                )} />                            
                            </div>                            
                            <div style={{marginBottom: '2em'}}>      
                                <Divider align="center">
                                    <span className="p-tag">Security Details</span>
                                </Divider>
                            </div>
                            <div style={{ width: '100%', height: '300px' }} className="p-grid table-demo">
                                <div className="p-col-12">
                                    <DataTable value={cltData} scrollable scrollHeight="250px" className="p-datatable-customers" 
                                        selectionMode="single" selection={cltRowData} onSelectionChange={e => setCltRowData(e.value)}                                        
                                        emptyMessage="No Clients found." >                        
                                        <Column field="user_id" header="UserId" headerStyle={{ width: '16em' }} sortable body={bodyTemplate}></Column>
                                        <Column field="client_id" header="ClientId" headerStyle={{ width: '8em' }} sortable body={bodyTemplate}></Column>
                                        <Column field="user_name" header="Name" sortable body={bodyTemplate} />
                                        <Column field="email" header="Email" sortable body={bodyTemplate} />
                                    </DataTable>                                
                                </div>
                            </div>
                            <div className="p-field" style={{marginTop: '2em', marginBottom: '2em'}}>
                                <Button label="Add Client" style={{width: '15em'}} onClick={() => ( setCltFields(), push('Clients', clientInfo) ) } />
                            </div>
                            <FieldArray name="Clients">                                    
                                {({ fields }) => fields.map((name, index) => (
                                    <>                                    
                                    <div className="p-formgroup-inline" key={name}>
                                        <div className="p-field" style={{marginTop: '0.5em'}}>
                                            <label className="p-checkbox-label">Client Details #{index + 1} :</label>
                                        </div>
                                        <Field name={`${name}.user_id`} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label">
                                                    <InputText id={`${name}.user_id`} {...input} readOnly className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                                    <label htmlFor={`${name}.user_id`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>UserId</label>   
                                                </span>
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />
                                        <Field name={`${name}.user_name`} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label">
                                                    <InputText id={`${name}.user_name`} {...input} readOnly className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                                    <label htmlFor={`${name}.user_name`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>UserName</label>   
                                                </span>
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />
                                        <Field name={`${name}.perc`} validate={composeValidators(required)} render={({ input, meta }) => (
                                            <div className="p-field">
                                                <span className="p-float-label" style={{width: '12em'}}>                                            
                                                    <InputText id={`${name}.perc`} {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase} />
                                                    <label htmlFor={`${name}.perc`} className={classNames({ 'p-error': isFormFieldValid(meta) })}>Percentage*</label>
                                                </span>
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />                                       

                                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => fields.remove(index)}/>
                                    </div>
                                    </>
                                )) }
                            </FieldArray>
                                                                                    
                            <Button type="submit" label="Submit" className="p-mt-2" disabled={submitting || pristine} />
                            {/* <pre>{JSON.stringify(values, 0, 2)}</pre>  */}
                        </form>
                    )} />
                </div>
            </div>
        </div>
    );
}