import React, {useState }from 'react';
import { Form, Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { Link, useNavigate} from 'react-router-dom'
import  useToken  from './useToken';
import './LoginForm.css';

export const Login = (props) => {        
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const { userInfo, setToken } = useToken();
    const navigate = useNavigate();

    const validate = (data) => {
        let errors = {};

        if (!data.userid) {
            errors.userid = 'UserId is required.';
        }
        /*
        else if (!/^[A-Z0-9_-]$/i.test(data.userid)) {
            errors.userid = 'Invalid UserID';
        }*/

        if (!data.password) {
            errors.password = 'Password is required.';
        }       
        return errors;
    };    
    
    const OnSubmit = async (data, form) => {
        const response =  await fetch('/raffles/login.php', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                  "Content-type": "application/json; charset=UTF-8"
                }
                }
            );

        var userResponse = await response.json();
        //console.log(userResponse.userid);        
        if (userResponse.message === "Success") {
            props.onAuthenticate(true);
            setToken(userResponse);

            //console.log(userResponse.registration);
            //navigate('/RegisterDetails')
            
            if (userResponse.registration <= 1) {
                navigate('/RegisterDetails')
            }
            else if (userResponse.registration > 1 && userResponse.registration <= 2) {                
                navigate('/RegisterKYC')
            }
            else if (userResponse.registration > 2 && userResponse.registration <= 3) {
                navigate('/RegisterUpload')
            }
            else {
                navigate('/')
            }
            
        }
        else {
            setFormData(userResponse);
            setShowMessage(true);
            form.restart();
        }        
    }

    async function OnResetPassword(data, form) {    
        if (!data.userid) {
            alert("Please enter UserID");
        }
        else {
            const response =  await fetch('/raffles/reset_password.php', {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                    "Content-type": "application/json; charset=UTF-8"
                    }
                    }
                );

            var userResponse = await response.json();
            //console.log(userResponse.userid);        
            if (userResponse.message === "Success") {
                setFormData(userResponse);
                setShowMessage(true);
                form.restart();              
            }
            else {
                setFormData(userResponse);
                setShowMessage(true);                
            }        
        }
    }
    
    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-mt-2" autoFocus onClick={() => setShowMessage(false) } /></div>;
    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    return (
        <div className="login-form">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
                    <i className="pi pi-exclamation-circle" style={{ fontSize: '5rem', color: '#f00' }}></i>
                    <h5>Password Reset Error: {formData.message}</h5>                    
                </div>
            </Dialog>
            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <div className="p-text-center">
                        <img src="/raffles2_blue_small_1.png" alt="Logo" height={50} className="mb-3" />                        
                    </div>
                    <Divider type="solid" />
                    <div className="text-900 text-3xl font-medium mb-3"><h5>Login</h5></div> 
                    <div className="p-grid">
                        <div className="p-col-5 p-d-flex p-ai-center p-jc-center">
                            <Form onSubmit={OnSubmit} initialValues={{ userid: '', password: '', remember: false }} validate={validate} 
                                render={({ handleSubmit, submitting, pristine,values, form }) => (
                                <form onSubmit={handleSubmit} className="p-fluid">                            
                                    <Field name="userid" render={({ input, meta }) => (
                                        <div className="p-field">
                                            <span className="p-float-label p-input-icon-right">
                                                <i className="pi pi-user" />
                                                <InputText id="userid" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} onInput={toInputUppercase}/>
                                                <label htmlFor="userid" className={classNames({ 'p-error': isFormFieldValid(meta) })}>UserID*</label>
                                            </span>
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    <Field name="password" render={({ input, meta }) => (
                                        <div className="p-field">
                                            <span className="p-float-label">
                                                <Password id="password" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                                <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Password*</label>
                                            </span>
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    <Field name="remember" type="checkbox" render={({ input, meta }) => (
                                        <div className="p-field-checkbox">
                                            <Checkbox inputId="accept" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                            <label htmlFor="accept" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Remember Me</label>
                                        </div>
                                    )} />
                                    
                                    
                                    <Button type="submit" label="Login" className="p-mt-2" disabled={submitting || pristine}/>                                    
                                    <Button label="Reset Password" className="p-mt-2" onClick={e => (e.preventDefault(), OnResetPassword(values,form))} ></Button>
                                    
                                </form>
                            )} />
                        </div>
                        <div className="p-col-2">
                            <Divider layout="vertical">
                                <b>OR</b>
                            </Divider>
                        </div>
                        <div className="p-col-5 p-d-flex p-ai-center p-jc-center">                            
                            <Link to="/Register">
                                <Button label="Sign Up" icon="pi pi-user-plus" className="p-button-success"></Button>
                            </Link>                            
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    )
}
