import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const MarketGrid = () => {
    const [marketGrid, setRowData] = useState(null);

    useEffect(() => {
        fetch('/Market/get_watchview.php')
        .then(result => result.json())
        .then(rowData => setRowData(rowData))
        }, []);

    const marketGridHeader = (
        <div className="table-header">
            NIFTY 200            
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}                
            </>
        );
    };

    const numberTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <div style={{ textAlign: 'right' }}>{numberParser(data[props.field])}</div>
            </>
        );
    };

    const rsiTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {rsiParser(data[props.field])}                
            </>
        );
    };

    const sma200Template = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {sma200Parser(data)}                
            </>
        );
    };
    

    function numberParser(value) {          
        var valueAsNumber;
        if (value === null || value === undefined || value === '') {
          valueAsNumber = 0;
        } else {
          valueAsNumber = parseFloat(value);
        }
        return valueAsNumber;
    }

    function rsiParser(value) {          
        var valueAsNumber;
        if (value === null || value === undefined || value === '') {
          valueAsNumber = 0;
        } else {
          valueAsNumber = parseFloat(value);
        }
        if (valueAsNumber < 25)
            return (<div style={{ textAlign: 'right', color: '#fff', background: '#00A' }}>{valueAsNumber}</div>)
        else if (valueAsNumber > 75)
            return (<div style={{ textAlign: 'right', color: '#fff', background: '#A00' }}>{valueAsNumber}</div>)
        else
            return (<div style={{ textAlign: 'right' }}>{valueAsNumber}</div>)
    }

    function sma200Parser(data) {          
        var close;
        var sma;
        var sma200;

        close = parseFloat(data.close_price);
        sma = parseFloat(data.sma);
        sma200 = parseFloat(data.sma200);
        
        if (sma >= sma200 && close <= sma200) 
            return (<div style={{ textAlign: 'right', color: '#fff', background: '#0A0'}}>{sma200}</div>)
        else
            return (<div style={{ textAlign: 'right' }}>{sma200}</div>)
    }
    
    return (
        <div style={{ width: '100%', height: '88%' }} className="p-grid table-demo">
            <div className="p-col-12">
                <DataTable value={marketGrid} className="p-datatable-customers"
                    emptyMessage="No Securities found." header={marketGridHeader}>                        
                    <Column field="stk_name" header="Name" sortable body={bodyTemplate}></Column>
                    <Column field="stk_me" header="ME" headerStyle={{ width: '5em' }} sortable body={bodyTemplate}></Column>
                    <Column field="open_price" header="Open"  headerStyle={{ width: '10em' }} body={numberTemplate}></Column>
                    <Column field="high_price" header="High" headerStyle={{ width: '10em' }} body={numberTemplate}></Column>
                    <Column field="low_price" header="Low" headerStyle={{ width: '10em' }} body={numberTemplate}></Column>
                    <Column field="close_price" header="Close" headerStyle={{ width: '10em' }} body={numberTemplate}></Column>
                    <Column field="net_perc" header="Net Perc" headerStyle={{ width: '10em' }} sortable body={numberTemplate}></Column>
                    <Column field="rsi" header="RSI" sortable headerStyle={{ width: '10em' }} body={rsiTemplate}></Column>
                    <Column field="sma" header="SMA" headerStyle={{ width: '10em' }} body={numberTemplate}></Column>
                    <Column field="sma24" header="SMA24" headerStyle={{ width: '10em' }} body={numberTemplate}></Column>
                    <Column field="sma200" header="SMA200" headerStyle={{ width: '10em' }} body={sma200Template}></Column>                        
                </DataTable>                
            </div>
        </div>
    )
}
