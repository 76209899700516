import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate} from 'react-router-dom'
import { Steps } from 'primereact/steps';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import FileField from "./FileField";
import  useToken from './useToken';
import './LoginForm.css';


export const RegisterUpload = () => {
    const [activeIndex, setActiveIndex] = useState(3);
    const [showMessage, setShowMessage] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();
    const { userInfo, getToken } = useToken();
    var uploadResponse="";
    const items = [        
        {
            label: 'UserId creation'
            
        },
        {
            label: 'Personal'
            
        },
        {
            label: 'KYC details'
        },
        {
            label: 'Upload Documents'
        }
    ];

    const onSubmit = async(data) => {        
        var userid=userInfo.userid;
        var address_ctr=0;
        var passport_ctr=0;
        var formData = new FormData();
        
        if (data.address_files) {
            data.address_files.map((file, index) => {            
                formData.append(`address_file${index}`, file);
                address_ctr++;
            });
        }

        if (data.passport_files) {
            data.passport_files.map((file, index) => {
                formData.append(`passport_file${index}`, file);
                passport_ctr++;
            });
        }

        if (address_ctr > 0 &&  passport_ctr > 0) {
            formData.append('userid', userid);
            formData.append('address_ctr', address_ctr);
            formData.append('passport_ctr', passport_ctr);

            const response = await fetch('/raffles/uploadFile.php', {
                method: 'POST',
                body: formData
                }
            );

            var userResponse = await response.json();
            //console.log(userResponse);
            setResponseMessage(userResponse.message);            
            setShowMessage(true);
        }            
    };


    function RegisterResponse() {
        //console.log(responseMessage);
        if (responseMessage === "Success") {
            return  (
                <>
                <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                <h5>Documents Uploaded Successfully!</h5>                
                </>
            );  
        }
        else {
            return  (
                <>
                <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                <h5>Documents Upload Failed!</h5>                
                </>
            );
        }        
    }

    function onShowMessageClick() {
        setShowMessage(false);
        if (responseMessage === "Success") {         
            navigate('/')
        }
    }

    const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={onShowMessageClick } /></div>;
    
    return (
        <div className="register-form">
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">                    
                    <RegisterResponse  />
                </div>
            </Dialog>
            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h5 className="p-text-center">Registration - Upload Documents</h5>                    
                    <Steps model={items} activeIndex={activeIndex} readOnly={true} />
                    <div className="p-d-flex p-ai-center p-jc-center" style={{marginTop: '2em'}}>
                     <Form onSubmit={onSubmit} initialValues={{ files:null }}>
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="p-col-12 p-lg-4" style={{ width: '100%' }} >
                                    <Panel header="Upload Address Documents" style={{ height: '100%' }}>
                                        <FileField name="address_files" label="Address"/>
                                    </Panel>
                                </div>
                                <div className="p-col-12 p-lg-4" style={{ width: '100%' }} >
                                    <Panel header="Upload Passport Documents" style={{ height: '100%' }}>
                                        <FileField name="passport_files" label="Passport"/>
                                    </Panel>
                                </div>
                            <Button type="submit" label="Submit" className="p-mt-2" />
                            </form>
                        )}
                    </Form>
                    </div>
                </div>
            </div>
        </div>
    );   

}