import React, { useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import {Link} from 'react-router-dom'
import  useToken from './components/useToken';

export const AppProfile = (props) => {
    const [expanded, setExpanded] = useState(false);
    const { userInfo, getToken } = useToken();

    function checkLogin() {
        //if(!userInfo) {
        if(!props.userState) {        
            return (
                <>
                </>
            )
        }
        else {
            return (
                <div className="layout-profile">
                    <div>
                        <img src="assets/layout/images/profile.png" alt="Profile" />
                    </div>
                    <button className="p-link layout-profile-link" onClick={onClick}>
                        <span className="username">{userInfo.username}</span>
                        <i className="pi pi-fw pi-cog" />
                    </button>
                    <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                        <ul className={classNames({ 'layout-profile-expanded': expanded })}>
                            <li><Link to="/AccountDetails"><button type="button" className="p-link"><i className="pi pi-fw pi-user" /><span>Account</span></button></Link></li>
                            <li><Link to="/ChangePassword"><button type="button" className="p-link"><i className="pi pi-fw pi-user" /><span>Change Password</span></button></Link></li>
                            <li><Link to="/Logout"><button type="button" className="p-link"><i className="pi pi-fw pi-power-off" /><span>Logout</span></button></Link></li>
                        </ul>
                    </CSSTransition>
                </div>
            )
        }
    }

    const onClick = (event) => {
        setExpanded(prevState => !prevState);
        event.preventDefault();
    }

    return (
        <div className="layout-profile">
            {checkLogin()} 
        </div>
    );

}
