import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import  useToken from './useToken';

export const UserDashboard = () => {
    const [subfundData, setSubFund] = useState(null);
    const { userInfo, getToken } = useToken();
    
    useEffect(() => {
        let subfund_request = {};
        subfund_request.clientid=userInfo.clientid;
        fetch('/raffles/get_user_subfunds.php', {
            method: 'POST',
            body: JSON.stringify(subfund_request),
            headers: {
            "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then(result => result.json())
        .then(rowData => setSubFund(rowData))        
    }, []);

    function DisplayCardSubFunds() {        
        const htmlData = [];
        let sub_fund_id="";
        let sub_fund_name="";        
        if (subfundData) {
            for (let i in subfundData) {
                sub_fund_id = subfundData[i].sub_fund_id;
                sub_fund_name = subfundData[i].sub_fund_name;
                 htmlData.push(<div className="p-col-12 p-lg-2 subfund">
                                <Card title={subfundData[i].sub_fund_name}  >                                
                                    <table width="100%" >
                                        <tr><td><span >Transferred Amount</span></td><td align='right'><span>{subfundData[i].base_capital}</span></td></tr>
                                        <tr><td><span>Utilized Amount</span></td><td align='right'><span>{subfundData[i].utilization}</span></td></tr>
                                        <tr><td><span>Available Amount</span></td><td align='right'><span>{subfundData[i].available}</span></td></tr>
                                    </table>                                
                                    <Link to={"/FundRequest"} state={{ from: {sub_fund_id,sub_fund_name}, request_type: "INVEST" }}>      
                                        <Button label="Invest" className="p-mt-2"/>
                                    </Link>
                                    <Link to={"/FundRequest"} state={{ from: {sub_fund_id,sub_fund_name}, request_type: "REDEEM" }}>
                                        <Button label="Redeem" className="p-button-warning p-mt-2"/>
                                    </Link>
                                </Card>
                            </div>);                
            }
        }
        
        return (htmlData);
    }

    return (
        <div className="p-grid p-fluid dashboard">            
            <DisplayCardSubFunds />            
        </div>
    );
}