import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Chart } from 'primereact/chart';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const dropdownCities = [
    { name: 'India', code: 'IN'},
    { name: 'Hong Kong', code: 'HK' },    
    { name: 'Singapore', code: 'SG' }
];

const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860'
        },
        {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e'
        }
    ]
};

export const Home = () => {

    const [dropdownCity, setDropdownCity] = useState(null);
    const [currencyRates, setcurrencyRates] = useState(null);

    useEffect(() => {
        fetch('/raffles/get_currency_rates.php')
        .then(result => result.json())
        .then(rowData => setcurrencyRates(rowData))
    }, []);

    const formatCurrency = (value,curr_type) => {
        return value.toLocaleString('en-US', { minimumFractionDigits: 4, style: 'currency', currency: curr_type });
    };

    const numberTemplate = (data, props) => {
        return (
            <>                
                <div style={{ textAlign: 'right' }}>{formatCurrency(parseFloat(data[props.field]),data.currency)}</div>
            </>
        );
    };

    return (
        <div className="p-grid p-fluid dashboard">
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Orders</span>
                    <span className="detail">Number of Orders</span>
                    <span className="count visitors">12</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Trades</span>
                    <span className="detail">Number of Trades</span>
                    <span className="count purchases">534</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Revenue</span>
                    <span className="detail">Income for today</span>
                    <span className="count revenue">$3,200</span>
                </div>
            </div>

            <div className="p-col-12 p-lg-4">
                <Panel header="Currency Rates">                
                    <DataTable value={currencyRates} className="p-datatable-customers" rows={3} style={{ marginBottom: '20px'}} paginator>                        
                        <Column field="pair" header="Currency" sortable></Column>                        
                        <Column field="rate" header="Rate" body={numberTemplate}></Column>                        
                    </DataTable>
                </Panel>                
            </div>

            <div className="p-col-12 p-md-6 p-lg-4 p-fluid contact-form">
                <Panel header="Contact Us">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Dropdown value={dropdownCity} onChange={(e) => setDropdownCity(e.value)} options={dropdownCities} optionLabel="name" placeholder="Select a City" />
                        </div>
                        <div className="p-col-12">
                            <InputText type="text" placeholder="Name" />
                        </div>
                        <div className="p-col-12">
                            {/* <InputText type="text" placeholder="Phone" /> */}
                            <InputMask mask="(999) 9999999999" placeholder="Phone" /> 
                        </div>
                        <div className="p-col-12">
                            <InputText type="text" placeholder="Email" />
                        </div>
                        <div className="p-col-12">
                            <Button type="button" label="Send" icon="pi pi-envelope" />
                        </div>
                    </div>
                </Panel>
            </div>

            <div className="p-col-12 p-lg-4 contacts">
                <Panel header="Contacts">
                    <ul>
                        <li>
                            <button className="p-link">
                                <img src="assets/layout/images/avatar_1.png" width="35" alt="avatar1" />
                                <span className="name">Contact 1</span>
                                <span className="email">contact1@raffles2.com</span>
                            </button>
                        </li>
                        <li>
                            <button className="p-link">
                                <img src="assets/layout/images/avatar_2.png" width="35" alt="avatar2" />
                                <span className="name">Contact 2</span>
                                <span className="email">contact2@raffles2.com</span>
                            </button>
                        </li>
                        <li>
                            <button className="p-link">
                                <img src="assets/layout/images/avatar_3.png" width="35" alt="avatar3" />
                                <span className="name">Contact 3</span>
                                <span className="email">contact3@raffles2.com</span>
                            </button>
                        </li>
                        <li>
                            <button className="p-link">
                                <img src="assets/layout/images/avatar_4.png" width="35" alt="avatar4" />
                                <span className="name">Contact 4</span>
                                <span className="email">contact4@raffles2.com</span>
                            </button>
                        </li>
                    </ul>
                </Panel>
            </div>            

            <div className="p-col-12 p-lg-4">
                <Panel header="Activity" style={{ height: '100%' }}>
                    <div className="activity-header">
                        <div className="p-grid">
                            <div className="p-col-6">
                                <span style={{ fontWeight: 'bold' }}>Last Activity</span>
                                <p>Updated 1 minute ago</p>
                            </div>
                            <div className="p-col-6" style={{ textAlign: 'right' }}>
                                <Button label="Refresh" icon="pi pi-refresh" />
                            </div>
                        </div>
                    </div>

                    <ul className="activity-list">
                        <li>
                            <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
                                <h5 className="activity p-m-0">Income</h5>
                                <div className="count">$900</div>
                            </div>
                            <ProgressBar value={95} showValue={false} />
                        </li>
                        <li>
                            <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
                                <h5 className="activity p-m-0">Tax</h5>
                                <div className="count" style={{ backgroundColor: '#f9c851' }}>$250</div>
                            </div>
                            <ProgressBar value={24} showValue={false} />
                        </li>
                        {/*<li>
                            <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
                                <h5 className="activity p-m-0">Invoices</h5>
                                <div className="count" style={{ backgroundColor: '#20d077' }}>$125</div>
                            </div>
                            <ProgressBar value={55} showValue={false} />
                        </li>
                        <li>
                            <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
                                <h5 className="activity p-m-0">Expenses</h5>
                                <div className="count" style={{ backgroundColor: '#f9c851' }}>$250</div>
                            </div>
                            <ProgressBar value={15} showValue={false} />
                        </li>
                        <li>
                            <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
                                <h5 className="activity p-m-0">Bonus</h5>
                                <div className="count" style={{ backgroundColor: '#007be5' }}>$350</div>
                            </div>
                            <ProgressBar value={5} showValue={false} />
                        </li>
                        <li>
                            <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
                                <h5 className="activity p-m-0">Revenue</h5>
                                <div className="count" style={{ backgroundColor: '#ef6262' }}>$500</div>
                            </div>
                            <ProgressBar value={25} showValue={false} />
                        </li> */}
                    </ul>
                </Panel>
            </div>

            <div className="p-col-12 p-lg-4">
                <div className="card">
                    <Chart type="line" data={lineData} />
                </div>
            </div>
        </div>
    );
}
