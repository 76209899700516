import React, { useCallback } from "react";
import { Field } from "react-final-form";
import { useDropzone } from "react-dropzone";
import { Button } from 'primereact/button';

const FileField = ({ name, ...props }) => {
  return (
    <>
      <Field name={name} {...props} component={FileFieldInput} />
      <Field
        name={name}
        subscribe={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) =>
          touched && error ? <span>{error}</span> : null
        }
      />
    </>
  );
};

function FileFieldInput({ required, input, dropZoneProps, ...props }) {
  const onDrop = useCallback(
    (files) => {
      input.onChange(files);
    },
    [input]
  );

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    noDrag: true,
    ...dropZoneProps
  });

  const files = acceptedFiles.map((file) => (
    <li>
        <div className="p-d-flex p-jc-between p-ai-center p-mb-3">    
            <span key={file.path}>
                {file.path} - {file.size} bytes
            </span>
        </div>
    </li>    
  ));

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button {...props} className="p-mt-2" icon="pi pi-upload" iconPos="right"/>
      <div className="p-col-12 p-lg-4" style={{ width: '100%' }}>            
        <ul className="files-list">
          {files}
        </ul>
      </div>
    </div>
  );
}

export default FileField;