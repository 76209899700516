import React, { useEffect, useState, useRef  } from 'react';
import { Link, useHistory} from 'react-router-dom'
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import  useToken from './useToken';
import './LoginForm.css';

export const ViewSubFund = () => {    
    const [SubFundData, setSubFundData] = useState(null);    
    const [SubFundRowData, setSubFundSecRowData] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const { userInfo, getToken } = useToken();
    const cm = useRef(null);

    const menuModel = [
        {label: 'Activate', icon: 'pi pi-fw pi-search', command: () => activateSubFund(SubFundRowData)},
        {label: 'Suspend', icon: 'pi pi-fw pi-times', command: () => suspendSubFund(SubFundRowData)}
    ];

    useEffect(() => {
        fetch('/raffles/get_subfund.php')
        .then(result => result.json())
        .then(SubFundData => setSubFundData(SubFundData))
    }, []);

    const SubFundGridHeader = (
        <div className="table-header">
            SubFund Master           
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}                
            </>
        );
    };

    const numberTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                <div style={{ textAlign: 'right' }}>{numberParser(data[props.field])}</div>
            </>
        );
    };
    
    function numberParser(value) {          
        var valueAsNumber;
        if (value === null || value === undefined || value === '') {
          valueAsNumber = 0;
        } else {
          valueAsNumber = parseFloat(value);
        }
        return valueAsNumber;
    }

    const activateSubFund = async(SubFundRowData) => {
        let request = {userid:''};

        request.userid = userInfo.userid;
        request.sub_fund_id=SubFundRowData.sub_fund_id;
        request.status="ACTV";

        const response = await fetch('/raffles/upd_subfund_status.php', {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
            "Content-type": "application/json; charset=UTF-8"
            }
            }
        );

        var userResponse = await response.json();

        if (userResponse.message === "Success") {
            let _SubFundData = [...SubFundData];

            for (let i = 0; i < SubFundData.length; i++) {
                if (_SubFundData[i].sub_fund_id === SubFundRowData.sub_fund_id) {
                    _SubFundData[i].status="ACTV";
                    break;
                }
            }
            setSubFundData(_SubFundData);
        }        
    }

    const suspendSubFund = async(SubFundRowData) => {        
        let request = {userid:''};
        
        request.userid = userInfo.userid;
        request.sub_fund_id=SubFundRowData.sub_fund_id;
        request.status="DACT";

        const response = await fetch('/raffles/upd_subfund_status.php', {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
            "Content-type": "application/json; charset=UTF-8"
            }
            }
        );

        
        var userResponse = await response.json();        
        if (userResponse.message === "Success") {
            let _SubFundData = [...SubFundData];

            for (let i = 0; i < SubFundData.length; i++) {
                if (_SubFundData[i].sub_fund_id === SubFundRowData.sub_fund_id) {
                    _SubFundData[i].status="DACT";
                    break;
                }
            }

            setSubFundData(_SubFundData);
        }        
    }


    const expandAll = () => {
        let _expandedRows = {};
        SubFundData.forEach(p => _expandedRows[`${p.id}`] = true);

        setExpandedRows(_expandedRows);
    }

    const collapseAll = () => {
        setExpandedRows(null);
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable" style={{ width: '100%' }}>
                {/* <h5>Securities for {data.SubFund_name}</h5>             */}
                <DataTable value={data.details} responsiveLayout="scroll">
                    <Column field="client_id" header="ClientId" style={{ width: '15em' }} sortable body={bodyTemplate}></Column>
                    <Column field="perc" header="Percentage" style={{ width: '15em' }}  body={numberTemplate}></Column>
                    <Column field="base_capital" header="Base Capital" style={{ width: '15em' }}  body={numberTemplate}></Column>
                    <Column field="utilization" header="Utilization" style={{ width: '15em' }}  body={numberTemplate}></Column>
                    <Column field="available" header="Available" style={{ width: '15em' }}  body={numberTemplate}></Column>
                    {/*                
                    <Column field="transfer_date" header="Transfer Date" style={{ width: '20em' }} sortable body={bodyTemplate} />  
                    <Column field="value_date" header="Value Date" style={{ width: '20em' }} sortable body={bodyTemplate} />  */}
                </DataTable>
            </div>
        );
    }

    const header = (
        <div className="table-header-container">
            <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} className="p-mr-2" />
            <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} />
        </div>
    );


    return (
        <div className="register-form">
            <ContextMenu model={menuModel} ref={cm} onHide={() => setSubFundSecRowData(null)}/>
            <div className="p-d-flex p-jc-center">
                <div className="card">
                    <h5 className="p-text-center">SubFund Master</h5>
                    <div style={{marginBottom: '2em'}}>
                        <Divider align="center">
                            <span className="p-tag">SubFund Creation</span>
                        </Divider>
                        <div className="p-col-12 p-d-flex p-ai-center p-jc-center">                            
                            <Link to="/CreateSubFund">
                                <Button label="Create SubFund" icon="pi pi-calendar-plus" className="p-button-success"></Button>
                            </Link>                            
                        </div>
                        <Divider align="center">
                            <span className="p-tag">SubFund Details</span>
                        </Divider>
                        <div style={{ width: '100%', minheight: '370px' }} className="p-grid table-demo">
                            <div className="p-col-12">
                                <DataTable value={SubFundData} scrollable scrollHeight="550px" className="p-datatable-customers" 
                                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} 
                                    //selectionMode="single" selection={SubFundRowData} onSelectionChange={e => setSubFundSecRowData(e.value)}
                                    
                                    selectionMode="single" contextMenuSelection={SubFundRowData} onContextMenuSelectionChange={e => setSubFundSecRowData(e.value) }
                                    onContextMenu={e => cm.current.show(e.originalEvent)} responsiveLayout="scroll"
                                    dataKey="sub_fund_id"  emptyMessage="No Sub Fund data found." > 
                                    <Column expander style={{ width: '3em' }} />                      
                                    <Column field="sub_fund_name" header="Name" style={{ width: '25em' }} sortable body={bodyTemplate}></Column>
                                    <Column field="base_capital" header="Base Capital" style={{ width: '15em' }}  body={numberTemplate}></Column>
                                    <Column field="utilization" header="Utilization" style={{ width: '15em' }}  body={numberTemplate}></Column>
                                    <Column field="available" header="Available" style={{ width: '15em' }}  body={numberTemplate}></Column>
                                    <Column field="allocation_date" header="Allocation Date" style={{ width: '15em' }} sortable body={bodyTemplate} />                                    
                                    <Column field="status" header="Status" body={bodyTemplate} />
                                </DataTable>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
